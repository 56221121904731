import { BranchProductDetail, Product, ProductPrices } from 'typescript-fetch-api'
import { Middleware, Reducer } from 'redux'
import { Config } from '../../common/auth/types'

import { PlatformSupport } from '../../common/platform'
import { StoreState } from '../../common/root/reducer'
import { ChangePasswordRequestAction } from '../../common/forgotpassword/actions'
import { loggerMiddleware } from '../logging'
import { submitOrderToApi, getFCMToken, getEnvironment, signOutConfirmationDialog } from './functions'
import { logBreadCrumb, reportError } from '../logging/functions'
import { getBranchesFetchDateSharedPrefs, saveBranchesFetchDateSharedPrefs } from '../config/functions'
import { ViewProductPayload } from '../../common/product/actions'
import { reducer as integrationsReducer } from '../integrations/reducer'
import { reducer as platformReducer } from './reducer'
import { reducer as hnzReducer } from '../hnz/reducer'
import { getRemoteConfigByKey } from '../remoteConfig/functions'
import { toggleDrawer } from './actions'
import { DrawerType } from './types'
import { Paths } from '../navigation/types'

export type PlatformProduct = Product

/**
 * Basic product info required to render a product row (search result, mylist item, cart item, order item)
 */
export interface ProductInfo {
	sku: string
	primaryImageTitle?: string // displayed in Cart screen
	shortDescription?: string
	longDescription?: string
	recommendedRetailPrice?: number
	accountPrice?: number
	tradePrice?: number
	recommendedRetailPriceGst?: number
	accountPriceGst?: number
	tradePriceGst?: number
	linePrice?: number // the price for the line
	unitPrice?: number // the price for the unite for the line
	hnzPrice?: number
	hnzPriceGst?: number
	hnz?: boolean
	hnzOnly?: boolean
	quantityAvailable?: number
	uom?: string
	isGoldProduct?: boolean
	hazardRating?: string
	isManufacturedItem: boolean
}

export interface ProductSearchResult extends Product { }

const platformSupportImplementation: PlatformSupport = {
	/** Customise the Redux middleware for this platform */
	customiseReduxMiddleware: (middlewares: Middleware[]): Middleware[] => {
		// logging
		middlewares.push(loggerMiddleware)
		return middlewares
	},

	customiseRootReducer: (reducer): Reducer<StoreState> => {
		return reducer
	},

	// Note: Update WebStoreState type in platform/types file
	customiseReducers: (reducers) => {
		// @ts-ignore
		reducers['integrations'] = integrationsReducer
		// @ts-ignore
		reducers['platform'] = platformReducer
		// @ts-ignore
		reducers['hnz'] = hnzReducer
		return reducers
	},

	customiseBlacklistedReducers: () => {
		return ['hnz', 'router']
	},

	createAuthConfiguration: (): Config => {
		return {
			tokenEndpoint: 'https://trade.plumbingworld.co.nz/FWTrain/fluid/FluidAppServerProxy',
		}
	},

	getPlatformClientId: (): string => {
		return 'plumbingworld-web-client'
	},

	getPlatformClientSecret: (): string => {
		return 'RvchP7uqSuJJD9WHYTvKRARW3wBgfYZZ'
	},

	submitOrderToApi: submitOrderToApi,

	convertPlatformProductToProductInfo: convertPlatformProductToProductInfo,

	convertPlatformProductToViewProductPayload: convertPlatformProductToViewProductPayload,

	getEnvironment: getEnvironment,

	getFCMToken: getFCMToken,

	logBreadCrumb: logBreadCrumb,

	reportError: reportError,

	signOutConfirmation: signOutConfirmationDialog,

	handleIncorrectSMSCode: handleIncorrectSMSCode,

	getBranchesFetchDateSharedPrefs: getBranchesFetchDateSharedPrefs,

	saveBranchesFetchDateSharedPrefs: saveBranchesFetchDateSharedPrefs,

	hasPinFeature: () => false,

	mapApiProductToPlatformProduct: (product: Product): PlatformProduct => product,

	mapProductPricesToProductInfo: mapProductPricesToProductInfo,

	getPricingPreferences: getPricingPreferences,

	getRemoteConfigByKey: getRemoteConfigByKey,

	appendPricingToProducts: appendPricingToProducts,

	appendAvailabilityToProducts: appendAvailabilityToProducts,

	appendAvailabilityToProduct: appendAvailabilityToProduct,

	navigateToSwitchAccount: () => toggleDrawer(DrawerType.ACCOUNT),

	isPerformingAroFloSignIn: () => {
		// if user is on the AroFlo sign in page
		return window.location.href.indexOf(Paths.AROFLO_PUNCHOUT) > -1
	},

}

export function getPlatformSupport(): PlatformSupport {
	return platformSupportImplementation
}

export function convertPlatformProductToProductInfo(product: PlatformProduct): ProductInfo {
	return {
		sku: product.sku,
		primaryImageTitle: product.primaryimage,
		shortDescription: product.shortdescription,
		longDescription: product.longdescription,
		recommendedRetailPrice: product.recommendedRetailPrice,
		accountPrice: product.accountPrice,
		tradePrice: product.tradePrice,
		recommendedRetailPriceGst: product.recommendedRetailPriceGst,
		accountPriceGst: product.accountPriceGst,
		tradePriceGst: product.tradePriceGst,
		hnzPrice: product.hnzPrice,
		hnzPriceGst: product.hnzPriceGst,
		hnz: product.hnz,
		hnzOnly: product.hnzOnly,
		quantityAvailable: product.quantityAvailable,
		uom: product.uom,
		isGoldProduct: product.isGoldProduct,
		hazardRating: product.attributes?.find(item => item.name === 'Hazard Rating')?.value,
		isManufacturedItem: product.isManufacturedItem,
	}
}

export function convertPlatformProductToViewProductPayload(product: PlatformProduct, hasExtraParams?: boolean): ViewProductPayload {
	return {
		productSku: product.sku,
		productDescription: product.shortdescription,
		productDetails: product,
		hasExtraParams,
	}
}

export function handleIncorrectSMSCode(action: ChangePasswordRequestAction, errorText: string) {
	//  on native this navigates the user
}

export function mapProductPricesToProductInfo(product: ProductInfo, productPrices: ProductPrices): ProductInfo {
	const { sku, ...rest } = productPrices
	return { ...product, ...rest }
}

export function appendPricingToProducts(products: ReadonlyArray<PlatformProduct>, prices: Array<ProductPrices>): Array<PlatformProduct> {
	return products.map(product => ({
		...product,
		...prices.find(priced => priced.sku === product.sku)
	}))
}

export function getPricingPreferences(): { retailEnabled: boolean, costEnabled: boolean } {
	return { retailEnabled: true, costEnabled: true }
}

export function appendAvailabilityToProducts(products: ReadonlyArray<PlatformProduct>, availability: Array<BranchProductDetail>): Array<PlatformProduct> {
	return products.map(product => ({
		...product,
		quantityAvailable: availability.find(count => count.productId === product.sku)?.stockAvailable
	}))
}

export function appendAvailabilityToProduct(product: PlatformProduct, availability: BranchProductDetail): PlatformProduct {
	return {
		...product,
		quantityAvailable: availability.stockAvailable
	}
}