import { reducerWithInitialState } from 'typescript-fsa-reducers/dist'
import * as actions from './actions'
import * as rootActions from '../root/actions'
import { CustomerOrder, Branch } from 'typescript-fetch-api'
import { clearAuthToken, loggedOut, login } from '../auth/actions'
import { removeLeadingZeros } from '../util/functions'
import { Filter } from '../util/types'

export interface StoreState {
	readonly orders: CustomerOrder[]
	readonly orderBranches: Branch[]
	readonly totalOrderPages?: number
	readonly totalOrdersCount?: number
	readonly loadingMessageGetOrders?: string
	readonly loadingMessageGetOrder?: string
	readonly downloadingCustomerOrders: Array<string>
	readonly errorFetchingCustomerOrders?: Error
	readonly errorFetchingCustomerOrder?: Error
	readonly fetchingCustomerOrders: boolean
	readonly fetchingCustomerOrderDetails: boolean
	readonly fromDate?: string
	readonly toDate?: string
	readonly filters: Filter[]
	readonly downloadCustomerOrdersAsZipLoading: boolean | undefined
	readonly downloadCustomerOrdersAsZipSuccess: boolean | undefined
	readonly downloadCustomerOrdersAsZipError: Error | undefined
	readonly emailOrdersLoading: boolean | undefined
	readonly emailOrdersSuccess: boolean | undefined
	readonly emailOrdersError: Error | undefined
}

/**
 * For loading customer orders from the server. This is different to the `order` reducer which handles the users cart and the process of making an order on the app (before it gets saved to the database and to network)
 */
const INITIAL_STATE: StoreState = {
	orders: [],
	orderBranches: [],
	totalOrderPages: undefined,
	totalOrdersCount: undefined,
	loadingMessageGetOrders: undefined,
	loadingMessageGetOrder: undefined,
	fetchingCustomerOrders: false,
	fetchingCustomerOrderDetails: false,
	fromDate: undefined,
	toDate: undefined,
	filters: [],
	downloadingCustomerOrders: [],
	downloadCustomerOrdersAsZipLoading: undefined,
	downloadCustomerOrdersAsZipSuccess: undefined,
	downloadCustomerOrdersAsZipError: undefined,
	emailOrdersLoading: undefined,
	emailOrdersSuccess: undefined,
	emailOrdersError: undefined,
}

export const reducer = reducerWithInitialState(INITIAL_STATE)

// FETCHING
// orders
reducer.case(actions.fetchCustomerOrders.started, (state): StoreState => {
	return {
		...state, loadingMessageGetOrders: 'Loading orders…', errorFetchingCustomerOrders: undefined, fetchingCustomerOrders: true, orderBranches: [], totalOrderPages: undefined, totalOrdersCount: undefined
	}
})
	.case(actions.fetchCustomerOrders.done, (state, payload): StoreState => {
		const orders = payload.result.orders || []
		const branches = payload.result.branches || []
		return {
			...state, orders, loadingMessageGetOrders: undefined, fetchingCustomerOrders: false, orderBranches: branches, totalOrderPages: payload.result.totalPagesCount, totalOrdersCount: payload.result.count
		}
	})
	.case(actions.fetchCustomerOrders.failed, (state, payload): StoreState => {
		return {
			...state, loadingMessageGetOrders: undefined, errorFetchingCustomerOrders: payload.error, fetchingCustomerOrders: false
		}
	})
	// order
	.case(actions.fetchCustomerOrder.started, (state): StoreState => {
		return {
			...state, loadingMessageGetOrder: 'Loading order…', errorFetchingCustomerOrder: undefined, fetchingCustomerOrderDetails: true
		}
	})
	.case(actions.fetchCustomerOrder.done, (state, payload): StoreState => {
		const orderWithDetails = payload.result.order
		let orders = state.orders
		if (orderWithDetails !== undefined) {
			// find the order and update it with the detailed order
			orders = orders.map(order => {
				if (removeLeadingZeros(order.orderNumber) === orderWithDetails.orderNumber && order.orderNumberSuffix === orderWithDetails.orderNumberSuffix ||
					orderWithDetails.orderNumber === order.orderNumber + '/' + order.orderNumberSuffix) { // for the case where the order number has a slash in it
					// store th original order number (with leading zeros)
					const orderNumber = order.orderNumber
					const orderNumberSuffix = order.orderNumberSuffix
					// update the order
					order = orderWithDetails
					// the order number set to include the leading zeros so it can be found in the customer orders
					order.orderNumber = orderNumber
					order.orderNumberSuffix = orderNumberSuffix
				}
				return order
			})
		}
		return {
			...state, orders, loadingMessageGetOrder: undefined, fetchingCustomerOrderDetails: false
		}
	})
	.case(actions.fetchCustomerOrder.failed, (state, payload): StoreState => {
		return {
			...state, loadingMessageGetOrder: undefined, errorFetchingCustomerOrder: payload.error, fetchingCustomerOrderDetails: false
		}
	})
	.case(actions.setCustomerOrdersFiltersAction, (state, payload): StoreState => {
		return {
			...state, filters: payload.filters
		}
	})
	.case(actions.setCustomerOrdersFromDateAction, (state, payload): StoreState => {
		return {
			...state, fromDate: payload.date
		}
	})
	.case(actions.setCustomerOrdersToDateAction, (state, payload): StoreState => {
		return {
			...state, toDate: payload.date
		}
	})
	.case(actions.downloadCustomerOrder.done, (state, payload): StoreState => {
		let downloadingCustomerOrders = state.downloadingCustomerOrders.filter((orderId) => orderId !== (payload.params.orderId + '/' + payload.params.orderSuffixId))
		return {
			...state,
			downloadingCustomerOrders,
		}
	})
	.case(actions.downloadCustomerOrder.failed, (state, payload): StoreState => {
		let downloadingCustomerOrders = state.downloadingCustomerOrders.filter((orderId) => orderId !== (payload.params.orderId + '/' + payload.params.orderSuffixId))
		return {
			...state,
			downloadingCustomerOrders,
		}
	})
	.case(actions.downloadCustomerOrder.started, (state, payload): StoreState => {
		let downloadingCustomerOrders: Array<string> = []
		downloadingCustomerOrders = downloadingCustomerOrders.concat(state.downloadingCustomerOrders)
		downloadingCustomerOrders = downloadingCustomerOrders.concat(payload.orderId + '/' + payload.orderSuffixId)
		return {
			...state,
			downloadingCustomerOrders,
		}
	})

	.case(actions.downloadCustomerOrderAsZip.started, (state): StoreState => {
		return {
			...state,
			downloadCustomerOrdersAsZipLoading: true,
			downloadCustomerOrdersAsZipSuccess: undefined,
			downloadCustomerOrdersAsZipError: undefined,
		}
	})
	.case(actions.downloadCustomerOrderAsZip.done, (state): StoreState => {
		return {
			...state,
			downloadCustomerOrdersAsZipLoading: undefined,
			downloadCustomerOrdersAsZipSuccess: true,
		}
	})
	.case(actions.downloadCustomerOrderAsZip.failed, (state, { error }): StoreState => {
		return {
			...state,
			downloadCustomerOrdersAsZipLoading: undefined,
			downloadCustomerOrdersAsZipError: error,
		}
	})

	.case(actions.emailCustomerOrders.started, (state): StoreState => {
		return {
			...state,
			emailOrdersLoading: true,
			emailOrdersSuccess: undefined,
			emailOrdersError: undefined,
		}
	})
	.case(actions.emailCustomerOrders.done, (state): StoreState => {
		return {
			...state,
			emailOrdersLoading: undefined,
			emailOrdersSuccess: true,
		}
	})
	.case(actions.emailCustomerOrders.failed, (state, { error }): StoreState => {
		return {
			...state,
			emailOrdersLoading: undefined,
			emailOrdersError: error,
		}
	})

	.cases([loggedOut, login.done, clearAuthToken], (): StoreState => {
		return INITIAL_STATE
	})

	// reset flags to their initial state
	.case(rootActions.readyAction, (state): StoreState => {
		return {
			...state,
			loadingMessageGetOrders: undefined,
			loadingMessageGetOrder: undefined,
			fetchingCustomerOrders: false,
			fetchingCustomerOrderDetails: false,
			errorFetchingCustomerOrders: undefined,
			errorFetchingCustomerOrder: undefined,
			downloadingCustomerOrders: [],
			downloadCustomerOrdersAsZipLoading: undefined,
			downloadCustomerOrdersAsZipSuccess: undefined,
			downloadCustomerOrdersAsZipError: undefined,
		}
	})