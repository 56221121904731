import { PriceBookFrequency } from 'typescript-fetch-api'

export enum PriceBookFileFormatLabel {
	TABDELIMITED = 'Tab Delimited (.txt)',
	COMMADELIMITED = 'Comma delimited (.txt)',
	COMMADELIMITED2 = 'Comma delimited (.csv)',
	DEFAULTFORMAT1 = 'Default Format 1 (.dat)',
	DEFAULTFORMAT2 = 'Default Format 2 (.dat)',
	WORKFLOWMAX = 'Work Flow Max (.csv)',
	SIMPRO = 'simPRO (.txt)',
	PRICEBOOK = 'Price Book Guide',
	FERGUS = 'Fergus Import Guide',
	FERGUSCSV = 'Fergus (.csv)',
	FERGUSTXT = 'Fergus (.txt)',
	TRADIFY = 'Tradify (.csv)',
	I_TRADE = 'iTrade (.csv)',
}

export interface PriceBookFrequencyOption {
	value: PriceBookFrequency
	label: string
	isDisabled?: boolean
}

export enum PriceBookRequestDialogType {
	MAX, SUCCESS
}