import { Channel, SagaIterator } from 'redux-saga'
import { call, takeEvery, put, select, actionChannel, take, fork } from 'redux-saga/effects'
import { Branch, GetInvoicesRequest, InvoiceDownloadType, InvoiceType, NotifyPaymentCompleteRequest, SetInvoiceCommentOperationRequest } from 'typescript-fetch-api'
import moment from 'moment'

import * as actions from './actions'
import { UserAccount } from '../accounts/types'
import { getDefaultInvoiceFromDate } from '../util/functions'
import { downloadFile } from '../../utils/functions'
import { invoiceFiltersSelector } from '../financials/selectors'
import { DateRange, InvoiceFilter, InvoiceFilterType } from '../financials/types'
import { selectedAccountSelector } from '../order/selectors'
import { getInvoiceApi, ApiErrorMessage } from '../api'
import { authTokenSelector } from '../auth/selectors'
import { callApi } from '../api/functions'
import { getInvoiceTypeDownloadTitle } from './functions'

/**
 * Peforms an edit of the comment for an invoice
 * @param action The action containing the edit invoice comment payload
 */
export function* handleEditInvoiceComment(action: actions.EditInvoiceCommentRequestAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.editInvoiceComment,
		(payload: actions.EditInvoiceCommentPayload) => {
			const requestParams: SetInvoiceCommentOperationRequest = {
				customerId: payload.customerId,
				invoiceId: payload.invoiceId,
				invoiceSuffixId: payload.invoiceSuffixId,
				setInvoiceCommentRequest: { comment: payload.comment },
			}
			return getInvoiceApi().setInvoiceComment(requestParams).catch((error) => {
				let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
				if (error instanceof Response) {
					switch (error.status) {
						case 403:
							errorText = 'Not authorized to edit this comment'
							break
						case 406:
							// map response body to get specific error message
							return error.json().then(body => {
								const message = body.message ? body.message : errorText
								throw new Error(message)
							})
						default:
							// @ts-ignore
							if (error.error_description) {
								// @ts-ignore
								errorText = error.error_description
							}
					}
				}
				throw new Error(errorText)
			})
		})
}

/**
 * Fetch invoices by account id. This is an admin endpoint.
 * @param action The action containing the get invoices payload
 */
export function* handleGetInvoices(action: actions.GetInvoicesRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getInvoices.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.getInvoices,
			(payload: actions.GetInvoicesPayload) => {
				const requestParams: GetInvoicesRequest = {
					customerId: payload.accountId,
					fromDate: payload.fromDate,
					toDate: payload.toDate,
					productId: payload.productId,
					branchId: payload.branchId,
					reference: payload.reference,
					invoiceNumber: payload.invoiceNumber,
					type: payload.type,
					greaterThan: payload.greaterThan,
					lessThan: payload.lessThan,
					comment: payload.comment,
					pwgoOnly: payload.pwgoOnly,
					page: payload.page,
					pageSize: payload.pageSize,
					includeZeroDollarInvoices: payload.includeZeroDollarInvoices,
				}
				return getInvoiceApi().getInvoices(requestParams)
					.catch((error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 403:
									errorText = 'Not authorized to view invoices'
									break
								case 406:
									// TODO need to send back specific error code/message for this
									errorText = 'Product does not exist'
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

export function* handleGetInvoicesForPage(action: actions.GetInvoicesForPageRequestAction): SagaIterator {
	const page: number = action.payload
	yield call(handleGetInvoicesWithFilters, page)
}

export function* handleInvoiceFiltersChanged(): SagaIterator {
	// reset to first page
	const page: number = 0
	yield call(handleGetInvoicesWithFilters, page)
}

export function* handleGetInvoicesWithFilters(page: number): SagaIterator {
	// get current selected account
	const account: UserAccount | undefined = yield select(selectedAccountSelector)
	if (!account) return

	// get current filters
	const filters: InvoiceFilter[] = yield select(invoiceFiltersSelector)

	const dateFilter: InvoiceFilter | undefined = filters.find(filter => filter.type === InvoiceFilterType.DATE)
	const dateRange: DateRange | undefined = dateFilter && dateFilter.value as DateRange
	const fromDate: string = dateRange ? dateRange.fromDate : getDefaultInvoiceFromDate()
	const toDate: string | undefined = dateRange && dateRange.toDate

	const branchFilter: InvoiceFilter | undefined = filters.find(filter => filter.type === InvoiceFilterType.BRANCH)
	const branchId: number | undefined = branchFilter && Number((branchFilter.value as Branch).id)

	const orderNumberFilter: InvoiceFilter | undefined = filters.find(filter => filter.type === InvoiceFilterType.ORDER_NO)
	const reference: string | undefined = orderNumberFilter && orderNumberFilter.value as string

	const invoiceNumberFilter: InvoiceFilter | undefined = filters.find(filter => filter.type === InvoiceFilterType.INVOICE_NO)
	const invoiceNumber: string | undefined = invoiceNumberFilter && invoiceNumberFilter.value as string

	const typeFilter: InvoiceFilter | undefined = filters.find(filter => filter.type === InvoiceFilterType.TYPE)
	const type: InvoiceType | undefined = typeFilter && typeFilter.value as InvoiceType

	const includeZeroDollarInvoices: boolean = filters.some(filter => filter.type === InvoiceFilterType.DISPLAY_$0_INVOICES)

	// if getting first page, do not append to list on redux
	const appendToList: boolean = page === 0 ? false : true
	// default page size for invoices (mobile)
	const pageSize: number = 10

	yield put(actions.getInvoices.started({ accountId: account.customerId, fromDate, toDate, productId: undefined, branchId, reference, invoiceNumber, type, pwgoOnly: false, page, pageSize, appendToList, includeZeroDollarInvoices }))
}

/**
 * Fetch statements by account id. This is an admin endpoint.
 * @param action The action containing the get statements payload
 */
export function* handleGetStatements(action: actions.GetStatementsRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getStatements.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.getStatements,
			(payload: actions.GetStatementsPayload) => {
				return getInvoiceApi().getStatements({ customerId: payload.accountId })
					.catch((error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 403:
									errorText = 'Not authorized to view statements'
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

/**
 * Fetch account summary by account id. This is an admin endpoint.
 * @param action The action containing the get account summary statement payload
 */
export function* handleGetAccountSummary(action: actions.GetAccountSummaryRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getAccountSummary.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.getAccountSummary,
			(payload: actions.GetAccountSummaryPayload) => {
				return getInvoiceApi().getAccountSummary({ customerId: payload.accountId })
					.catch((error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 403:
									errorText = 'Not authorized to view account summary'
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

/**
 * This channel allows the queueing of requests for downloading multiple invoices.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* downloadInvoicesChannel() {
	// create a channel for request actions to download an invoice
	const requestChannel: Channel<actions.DownloadInvoiceRequestAction> = yield actionChannel(actions.downloadInvoice.started)
	while (true) {
		// take from the channel
		const action: actions.DownloadInvoiceRequestAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleDownloadInvoice, action)
	}
}

/**
 * Downloads the invoice for given invoice details.
 * @param action The action containing the download invoice payload
 */
export function* handleDownloadInvoice(action: actions.DownloadInvoiceRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.downloadInvoice.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.downloadInvoice,
			(payload: actions.DownloadInvoicePayload) => {
				return getInvoiceApi()
					.downloadInvoice({
						customerId: payload.customerId,
						invoiceId: payload.invoiceId,
						invoiceSuffixId: payload.invoiceSuffixId,
						erp: payload.erp,
						type: payload.type,
					})
					.then((response: Blob) => {
						// extract type based on request
						const isPdf: boolean = payload.type === undefined || payload.type.startsWith('PDF') ? true : false
						const mimeType: string = isPdf ? 'application/pdf' : 'text/csv'
						// Create a Blob from the PDF Stream
						const file = new Blob(
							[response],
							{ type: mimeType })
						// chrome sometimes blocks large files being opened in new window, couldnt find exact size limit so using 2mb
						if (payload.view && isPdf && file.size < 2000000) {
							// Build a URL from the file
							const fileURL = URL.createObjectURL(file)
							// Open the URL on new Window
							window.open(fileURL, '_blank')
						} else {
							// create a download
							let filename
							// different file name for some types
							if (action.payload.type === InvoiceDownloadType.CSV_FERGUS || action.payload.type === InvoiceDownloadType.CSV_SIMPRO) {
								if (action.payload.type === InvoiceDownloadType.CSV_FERGUS) {
									const todaysDate = moment().format('DDMMYYYY')
									const type = action.payload.invoiceType && getInvoiceTypeDownloadTitle(action.payload.invoiceType)
									const typeTitle = type ? type + '_' : ''
									filename = 'PWL_' + action.payload.customerId + '_' + action.payload.invoiceId + '-' + action.payload.invoiceSuffixId + '_' + typeTitle + todaysDate + (isPdf ? '.pdf' : '.csv')
								} else {
									const todaysDate = moment().format('YYYYMMDD')
									filename = action.payload.customerId + '_' + todaysDate + '_' + action.payload.invoiceId + action.payload.invoiceSuffixId + (isPdf ? '.pdf' : '.csv')
								}
							} else {
								filename = (action.payload.date ? action.payload.date + '-' : '') + action.payload.invoiceId + '-' + action.payload.invoiceSuffixId + (isPdf ? '.pdf' : '.csv')
							}
							downloadFile(response, filename)
						}
					})
			})
	}
}

/**
 * Handles downloading a set of invoices as a zip file.
 * @param action The action containing the invoices to download as zip.
 */
export function* handleDownloadInvoicesAsZip(action: actions.DownloadInvoiceAsZipAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.downloadInvoiceAsZip,
		(payload: actions.DownloadInvoiceAsZipPayload) => {
			const { customerId, type, isCombined, invoices } = payload
			return getInvoiceApi()
				.downloadInvoices({
					customerId,
					type,
					isCombined,
					downloadInvoicesRequest: { invoices },
				})
				.then((response: Blob) => {
					// create a download 
					const filename = `${payload.customerId}-Invoices.zip`
					downloadFile(response, filename)
				})
		})
}

/**
 * Handles emailing invoices to the given email address.
 * @param action The action containing the invoices to email as zip.
 */
export function* handleEmailInvoices(action: actions.EmailInvoicesAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.emailInvoices,
		(payload: actions.EmailInvoicesPayload) => {
			const { customerId, type, isCombined, invoices, email } = payload
			return getInvoiceApi()
				.emailInvoices({
					customerId,
					type,
					isCombined,
					emailInvoicesRequest: { invoices, email },
				})
		})
}

/**
 * This channel allows the queueing of requests for downloading multiple statements.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* downloadStatementsChannel() {
	// create a channel for request actions to download a statement
	const requestChannel: Channel<actions.DownloadStatementRequestAction> = yield actionChannel(actions.downloadStatement.started)
	while (true) {
		// take from the channel
		const action: actions.DownloadStatementRequestAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		yield call(handleDownloadStatement, action)
	}
}

/**
 * Downloads the statement for given statement details.
 * @param action The action containing the download invoice payload
 */
export function* handleDownloadStatement(action: actions.DownloadStatementRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.downloadStatement.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.downloadStatement,
			(payload: actions.DownloadStatementPayload) => {
				return getInvoiceApi()
					.downloadStatement({
						customerId: payload.customerId,
						statementDate: payload.statementDate,
						type: payload.type,
					})
					.then((response: Blob) => {
						// extract type based on request
						const isPdf: boolean = payload.type === undefined || payload.type.startsWith('PDF') ? true : false
						const mimeType: string = isPdf ? 'application/pdf' : 'text/csv'
						// Create a Blob from the PDF Stream
						const file = new Blob(
							[response],
							{ type: mimeType })

						// chrome sometimes blocks large files being opened in new window, couldnt find exact size limit so using 2mb
						if (payload.view && isPdf && file.size < 2000000) {
							// Build a URL from the file
							const fileURL = URL.createObjectURL(file)
							// Open the URL on new Window
							window.open(fileURL, '_blank')
						} else {
							const filename: string = payload.statementDate + '-' + payload.customerId + (isPdf ? '.pdf' : '.csv')
							downloadFile(response, filename)
						}
					})
					.catch((error: Error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 404:
									errorText = ApiErrorMessage.STATEMENT_ERROR_MESSAGE
									break
								case 406:
									errorText = ApiErrorMessage.STATEMENT_HAS_NO_CONTENT
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			})
	}
}

/**
 * Handles downloading a set of statements as a zip file.
 * @param action The action containing the statements to download as zip.
 */
export function* handleDownloadStatementsAsZip(action: actions.DownloadStatementAsZipAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.downloadStatementAsZip,
		(payload: actions.DownloadStatementAsZipPayload) => {
			const { customerId, type, statements } = payload
			return getInvoiceApi()
				.downloadStatements({
					customerId: customerId,
					type,
					downloadStatementsRequest: { statements },
				})
				.then((response: Blob) => {
					// build up the file name
					let filename: string
					if (statements && statements.length > 0) {
						const lastStatementIndex: number = statements.length - 1
						filename = `${payload.customerId}-${statements[0].date}-${statements[lastStatementIndex].date}-Statements.zip`
					} else {
						filename = `${payload.customerId}-Statements.zip`
					}
					downloadFile(response, filename)
				})
		})
}

/**
 * Handles emailing statements to the given email address.
 * @param action The action containing the statements to email as zip.
 */
export function* handleEmailStatements(action: actions.EmailStatementsAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.emailStatements,
		(payload: actions.EmailStatementsPayload) => {
			const { customerId, type, statements, email } = payload
			return getInvoiceApi()
				.emailStatements({
					customerId,
					type,
					emailStatementsRequest: { statements, email },
				})
				.catch((error: Error) => {
					let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
					if (error instanceof Response) {
						switch (error.status) {
							case 404:
								errorText = ApiErrorMessage.STATEMENT_ERROR_MESSAGE
								break
							case 406:
								errorText = ApiErrorMessage.STATEMENT_HAS_NO_CONTENT
								break
							default:
								// @ts-ignore
								if (error.error_description) {
									// @ts-ignore
									errorText = error.error_description
								}
						}
					}
					throw new Error(errorText)
				})
		})
}

/**
 * Fetch account details by account id.
 * @param action The action containing the get account details payload
 */
export function* handleGetAccountDetails(action: actions.GetAccountDetailsRequestAction): SagaIterator {
	// check has auth token
	const hasAuthToken: boolean = (yield select(authTokenSelector)) !== undefined
	if (!hasAuthToken) {
		yield put(actions.getAccountDetails.failed({ params: action.payload, error: new Error(ApiErrorMessage.ERROR_NOT_LOGGED_IN) }))
	} else {
		yield call(
			// @ts-ignore API
			callApi,
			action,
			actions.getAccountDetails,
			(payload: actions.GetAccountDetailsPayload) => {
				return getInvoiceApi().getAccountDetails({ customerId: payload.accountId })
					.catch((error) => {
						let errorText: string = ApiErrorMessage.GENERIC_ERROR_MESSAGE
						if (error instanceof Response) {
							switch (error.status) {
								case 403:
									errorText = 'Not authorized to get account details'
									break
								default:
									// @ts-ignore
									if (error.error_description) {
										// @ts-ignore
										errorText = error.error_description
									}
							}
						}
						throw new Error(errorText)
					})
			}
		)
	}
}

function* handleRequestCreditLimitChange(action: actions.RequestCreditLimitChangeAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.requestCreditLimitChange,
		(payload: actions.RequestCreditLimitChangePayload) => {
			return getInvoiceApi().requestCreditLimitChange({ customerId: payload.accountId, requestCreditLimitChangeRequest: payload })
		}
	)
}

function* handleGetPaymentLink(action: actions.GetPaymentLinkAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.getPaymentLink,
		(payload: actions.GetPaymentLinkPayload) => {
			const hasPaymentFiles = payload.file && payload.file.length > 0
			if (hasPaymentFiles) {
				return getInvoiceApi().getPaymentLinkWithFileUpload(payload)
			}
			return getInvoiceApi().getPaymentLink({ customerId: payload.customerId, amount: payload.amount })
		}
	)
}

function* handleNotifyPaymentComplete(action: actions.NotifyPaymentCompleteAction): SagaIterator {
	yield call(
		// @ts-ignore callApi
		callApi,
		action,
		actions.notifyPaymentComplete,
		(payload: NotifyPaymentCompleteRequest) => {
			return getInvoiceApi().notifyPaymentComplete(payload)
		}
	)
}

/**
 * This channel allows the queueing of requests for creating/notifying payments.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 */
function* paymentsChannel() {
	const requestChannel: Channel<actions.GetPaymentLinkAction | actions.NotifyPaymentCompleteAction> = yield actionChannel([
		actions.getPaymentLink.started,
		actions.notifyPaymentComplete.started,
	])
	while (true) {
		// take from the channel
		const action: actions.GetPaymentLinkAction | actions.NotifyPaymentCompleteAction = yield take(requestChannel)
		// use a blocking call and perform the requests sequentially
		if (action.type === actions.getPaymentLink.started.type) {
			yield call(handleGetPaymentLink, action as actions.GetPaymentLinkAction)
		} else if (action.type === actions.notifyPaymentComplete.started.type) {
			yield call(handleNotifyPaymentComplete, action as actions.NotifyPaymentCompleteAction)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(downloadInvoicesChannel)
	yield fork(downloadStatementsChannel)
	yield fork(paymentsChannel)
	yield takeEvery(actions.getInvoices.started, handleGetInvoices)
	yield takeEvery(actions.getInvoicesForPage, handleGetInvoicesForPage)
	yield takeEvery(actions.setInvoiceFilter, handleInvoiceFiltersChanged)
	yield takeEvery(actions.clearInvoiceFilter, handleInvoiceFiltersChanged)
	yield takeEvery(actions.clearAllInvoiceFilters, handleInvoiceFiltersChanged)
	yield takeEvery(actions.getStatements.started, handleGetStatements)
	yield takeEvery(actions.getAccountSummary.started, handleGetAccountSummary)
	yield takeEvery(actions.editInvoiceComment.started, handleEditInvoiceComment)
	yield takeEvery(actions.downloadInvoiceAsZip.started, handleDownloadInvoicesAsZip)
	yield takeEvery(actions.downloadStatementAsZip.started, handleDownloadStatementsAsZip)
	yield takeEvery(actions.emailInvoices.started, handleEmailInvoices)
	yield takeEvery(actions.emailStatements.started, handleEmailStatements)
	yield takeEvery(actions.getAccountDetails.started, handleGetAccountDetails)
	yield takeEvery(actions.requestCreditLimitChange.started, handleRequestCreditLimitChange)
}