import { SagaIterator } from 'redux-saga'
import { takeEvery } from 'redux-saga/effects'

import { viewCustomerOrderDetailAction, ViewCustomerOrderDetailsAction } from '../../common/customerorders/actions'
import { navigateToBranchQuotes, navigateToOrdersTab, NavigateToOrdersTabAction } from '../platform/actions'
import { CustomerOrderDetailsNavigationState } from './types'
import * as NavigationManager from '../navigation/NavigationManager'
import { Paths } from '../navigation/types'


function handleNavigateToCustomerOrderDetails(action: ViewCustomerOrderDetailsAction) {
	const { orderId, orderSuffixId, orderReference, erp } = action.payload
	const state: CustomerOrderDetailsNavigationState = { orderReference, erp }
	if (NavigationManager.getLocation()?.pathname.startsWith(Paths.CUSTOMER_ORDERS)) {
		NavigationManager.navigateToCustomerOrderDetails(orderId, orderSuffixId.toString(), state)
	} else if (NavigationManager.getLocation()?.pathname.startsWith(Paths.INTERIM_ORDERS)) {
		NavigationManager.navigateToInterimOrderDetails(orderId, orderSuffixId.toString(), state)
	} else {
		NavigationManager.navigateToCustomerQuoteDetails(orderId, orderSuffixId.toString(), state)
	}
}

function handleNavigateToCustomerOrders(action: NavigateToOrdersTabAction) {
	if (action.payload.tab === 'labels') {
		NavigationManager.navigateToLabelOrders()
	} else if (action.payload.tab === 'interim-orders') {
		NavigationManager.navigateToInterimOrders()
	} else if (action.payload.tab === 'quotes') {
		NavigationManager.navigateToCustomerQuotes()
	} else {
		NavigationManager.navigateToCustomerOrders()
	}
}

function handleNavigateToBranchQuotes() {
	NavigationManager.navigateToCustomerQuotes()
}

export default function* (): SagaIterator {
	yield takeEvery(viewCustomerOrderDetailAction, handleNavigateToCustomerOrderDetails)
	yield takeEvery(navigateToOrdersTab, handleNavigateToCustomerOrders)
	yield takeEvery(navigateToBranchQuotes, handleNavigateToBranchQuotes)
}
