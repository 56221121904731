import actionCreatorFactory, { Action, Success } from 'typescript-fsa'
import { InvoicesResponse, StatementsResponse, InvoiceType, InvoiceMetaData, StatementMetaData, InvoiceDownloadType, DownloadStatementFileType, AccountDetails, RequestCreditLimitChangeType, ERP, PaymentLink, Statement, NotifyPaymentCompleteRequest, GetPaymentLinkWithFileUploadRequest } from 'typescript-fetch-api'
import { FinancialsTabEnum, InvoiceFilter, InvoiceFilterType, PaymentDetails } from './types'

const actionCreator = actionCreatorFactory('Financials')

export type FinancialTabOptions = FinancialsTabEnum.INVOICES | FinancialsTabEnum.STATEMENTS | FinancialsTabEnum.ACCOUNT_BALANCE
export interface NavigateToFinancialTabActionPayload {
	tab: FinancialTabOptions
	paymentDetails?: PaymentDetails
}
export type NavigateToFinancialTabAction = Action<NavigateToFinancialTabActionPayload>
export const navigateToFinancialsTab = actionCreator<NavigateToFinancialTabActionPayload>('NAVIGATE_TO_FINANCIALS_TAB')

export interface GetInvoicesPayload {
	accountId: number
	fromDate: string
	toDate?: string
	productId?: string
	branchId?: number
	reference?: string
	invoiceNumber?: string
	type?: InvoiceType
	greaterThan?: number
	lessThan?: number
	comment?: string
	pwgoOnly?: boolean
	page?: number
	pageSize?: number
	appendToList?: boolean
	includeZeroDollarInvoices?: boolean
}
export type GetInvoicesRequestAction = Action<GetInvoicesPayload>
export type GetInvoicesRequestSuccessAction = Action<Success<GetInvoicesPayload, InvoicesResponse>>
export const getInvoices = actionCreator.async<GetInvoicesPayload, InvoicesResponse, Error>('GET_INVOICES')

// needed a way to load next page of invoices from UI without having to pass in all the current filters from redux
// payload is the page number to load, if > 0, append results to existing array on redux
export type GetInvoicesForPageRequestAction = Action<number>
export const getInvoicesForPage = actionCreator<number>('GET_INVOICES_FOR_PAGE')

export const setInvoiceFilter = actionCreator<InvoiceFilter>('SET_INVOICE_FILTER')

export const clearInvoiceFilter = actionCreator<InvoiceFilterType>('CLEAR_INVOICE_FILTER')

export const clearAllInvoiceFilters = actionCreator('CLEAR_ALL_INVOICE_FILTERS')

export interface DownloadInvoicePayload {
	customerId: number
	invoiceId: string
	invoiceSuffixId: number
	erp?: ERP
	type: InvoiceDownloadType
	date?: string
	view?: boolean // opens the downloaded file
	invoiceType?: string
}
export type DownloadInvoiceRequestAction = Action<DownloadInvoicePayload>
export const downloadInvoice = actionCreator.async<DownloadInvoicePayload, InvoicesResponse, Error>('DOWNLOAD_INVOICE')

export interface DownloadInvoiceAsZipPayload {
	customerId: number
	type?: InvoiceDownloadType
	invoices?: InvoiceMetaData[]
	isCombined?: boolean
}
export type DownloadInvoiceAsZipAction = Action<DownloadInvoiceAsZipPayload>
export const downloadInvoiceAsZip = actionCreator.async<DownloadInvoiceAsZipPayload, Blob, Error>('DOWNLOAD_INVOICES_AS_ZIP')

export interface EmailInvoicesPayload {
	email: string
	customerId: number
	type: InvoiceDownloadType
	invoices: InvoiceMetaData[]
	isCombined?: boolean
}
export type EmailInvoicesAction = Action<EmailInvoicesPayload>
export const emailInvoices = actionCreator.async<EmailInvoicesPayload, void, Error>('EMAIL_INVOICES')

export interface GetStatementsPayload {
	accountId: number
}
export type GetStatementsRequestAction = Action<GetStatementsPayload>
export type GetStatementsRequestSuccessAction = Action<Success<GetStatementsPayload, StatementsResponse>>
export const getStatements = actionCreator.async<GetStatementsPayload, StatementsResponse, Error>('GET_STATEMENTS')

export interface GetAccountSummaryPayload {
	accountId: number
}
export type GetAccountSummaryRequestAction = Action<GetAccountSummaryPayload>
export type GetAccountSummaryRequestSuccessAction = Action<Success<GetAccountSummaryPayload, Statement>>
export const getAccountSummary = actionCreator.async<GetAccountSummaryPayload, Statement, Error>('GET_ACCOUNT_SUMMARY')

export interface GetAccountDetailsPayload {
	accountId: number
}
export type GetAccountDetailsRequestAction = Action<GetAccountDetailsPayload>
export type GetAccountDetailsRequestSuccessAction = Action<Success<GetAccountDetailsPayload, AccountDetails>>
export const getAccountDetails = actionCreator.async<GetAccountDetailsPayload, AccountDetails, Error>('GET_ACCOUNT_DETAILS')

export interface DownloadStatementPayload {
	customerId: number
	statementDate: string
	type: DownloadStatementFileType
	view?: boolean // opens the downloaded file
}
export type DownloadStatementRequestAction = Action<DownloadStatementPayload>
export const downloadStatement = actionCreator.async<DownloadStatementPayload, StatementsResponse, Error>('DOWNLOAD_STATEMENT')

export interface DownloadStatementAsZipPayload {
	customerId: number
	type?: DownloadStatementFileType
	statements?: StatementMetaData[]
}
export type DownloadStatementAsZipAction = Action<DownloadStatementAsZipPayload>
export const downloadStatementAsZip = actionCreator.async<DownloadStatementAsZipPayload, Blob, Error>('DOWNLOAD_STATEMENTS_AS_ZIP')

export interface EmailStatementsPayload {
	email: string
	customerId: number
	type: DownloadStatementFileType
	statements: StatementMetaData[]
}
export type EmailStatementsAction = Action<EmailStatementsPayload>
export const emailStatements = actionCreator.async<EmailStatementsPayload, void, Error>('EMAIL_STATMENTS')

export interface EditInvoiceCommentPayload {
	customerId: number
	invoiceId: string
	invoiceSuffixId: number
	comment: string
}
export type EditInvoiceCommentRequestAction = Action<EditInvoiceCommentPayload>
export const editInvoiceComment = actionCreator.async<EditInvoiceCommentPayload, StatementsResponse, Error>('EDIT_INVOICE_COMMENT')

export const clearInvoiceCommentError = actionCreator('CLEAR_INVOICE_COMMENT_ERROR')

export interface RequestCreditLimitChangePayload {
	accountId: number
	requestType: RequestCreditLimitChangeType
	newCreditLimit: string
	email: string
	comments?: string
	toDate?: string
}
export type RequestCreditLimitChangeAction = Action<RequestCreditLimitChangePayload>
export const requestCreditLimitChange = actionCreator.async<RequestCreditLimitChangePayload, void, Error>('REQUEST_CREDIT_LIMIT_CHANGE')

export interface GetPaymentLinkPayload extends GetPaymentLinkWithFileUploadRequest { }
export type GetPaymentLinkAction = Action<GetPaymentLinkPayload>
export type GetPaymentLinkSuccessAction = Action<Success<GetPaymentLinkPayload, PaymentLink>>
export const getPaymentLink = actionCreator.async<GetPaymentLinkPayload, PaymentLink, Error>('GET_PAYMENT_LINK')

export type NotifyPaymentCompleteAction = Action<NotifyPaymentCompleteRequest>
export const notifyPaymentComplete = actionCreator.async<NotifyPaymentCompleteRequest, void, Error>('NOTIFY_PAYMENT_COMPLETE')

export interface NavigateToPaymentPayload {
	link: string
	customerId: number
	amount: number
}
export type NavigateToPaymentAction = Action<NavigateToPaymentPayload>
export const navigateToPayment = actionCreator<NavigateToPaymentPayload>('NAVIGATE_TO_PAYMENT')

export const navigateToChangeLimit = actionCreator('NAVIGATE_TO_CHANGE_LIMIT')