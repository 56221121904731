import { Channel, SagaIterator } from "redux-saga"
import { actionChannel, call, flush, fork, put, race, select, take, takeEvery } from "redux-saga/effects"
import { BackflowProductBrand, BackflowProductType, BranchProductsRequest, Product, ProductPriceRequestList, ProductSku } from "typescript-fetch-api"

import { callApi } from '../api/functions'
import { getBranchApi, getContentApi, getUserApi } from "../api"
import * as actions from './actions'
import { getPriceParams } from "../auth/functions"
import { UserAccount } from "../accounts/types"
import { orderBranchSelector, selectedAccountSelector } from "../order/selectors"


function* handleFetchBackflowProductsByBrand(action: actions.FetchBackflowProductsByBrandAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchBackflowProductsByBrand, (payload: BackflowProductBrand) => {
		return getContentApi().searchBackflowProducts({ brand: payload })
	})
}

function* handleFetchBackflowProductsByType(action: actions.FetchBackflowProductsByTypeAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.fetchBackflowProductsByType, (payload: BackflowProductType) => {
		return getContentApi().searchBackflowProducts({ type: payload })
	})
}

function* loadBackflowProductPrices(action: actions.LoadBackflowProductPricesAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadBackflowProductPrices, ({ skus, customerId }: actions.LoadProductPricesPayload) => {
		if (skus.length === 0) return
		const products: ProductPriceRequestList = { products: skus }
		return getContentApi().getProductPrices({ productPriceRequestList: products, customerId })
	})
}

function* loadBackflowProductsAvailability(action: actions.GetBackflowProductsAvailabilityAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.getBackflowProductsAvailability,
		({ skus, branchId }: actions.GetBackflowProductsAvailabilityPayload) => {
			const products: BranchProductsRequest = { products: skus }
			return getBranchApi().getBranchProducts({ branchId, branchProductsRequest: products })
		}
	)
}

function* handleSendContactUs(action: actions.SendContactUsBackflowAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.sendContactUsBackflow,
		(payload: actions.SendContactUsBackflowPayload) => {
			const { reCaptchaToken, contactUsBackflowRequest } = payload
			return getUserApi().contactBackflow({ pwRec: reCaptchaToken, contactUsBackflowRequest })
		})
}

function* handleBackflowProductsLoaded(action: actions.BackflowProductsLoadedActionSuccess): SagaIterator {
	// get products from result
	const { result: { wilkins, watts, highHazard, lowMediumHazard, spareParts } } = action.payload
	const products: Product[] = [
		...(wilkins ?? []),
		...(watts ?? []),
		...(highHazard ?? []),
		...(lowMediumHazard ?? []),
		...(spareParts ?? [])
	]
	if (products.length === 0) return
	const skus: ProductSku[] = products.map(product => ({ sku: product.sku }))

	// load stock counts for product results
	const branchId: number | undefined = yield select(orderBranchSelector)
	if (branchId) {
		// dispatch the right action based on if doing query text search
		yield put(actions.getBackflowProductsAvailability.started({ branchId, skus }))
	}

	// to load hnzOnly products, must provide a hnz account id
	const selectedAccount: UserAccount | undefined = yield select(selectedAccountSelector)
	const { customerId, includePrices } = getPriceParams(selectedAccount)

	// load pricing
	if (includePrices) {
		yield put(actions.loadBackflowProductPrices.started({ customerId, skus }))
	}
}

/**
 * This channel allows the queueing of requests for loading product prices.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 * NOTE: This channel also handles clearing the channel queue whenever a cancel action is dispatched.
 */
function* loadBackflowProductPricesChannel() {
	// 1. Create a channel to watch for `loadBackflowProductPrices` actions and queue them
	const channel: Channel<actions.LoadBackflowProductPricesAction> = yield actionChannel(actions.loadBackflowProductPrices.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.LoadBackflowProductPricesAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(loadBackflowProductPrices, action),
			cancel: take(actions.cancelLoadBackflowProductPrices)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

function* loadBackflowProductsAvailabilityChannel() {
	// 1. Create a channel to watch for `getBackflowProductsAvailability` actions and queue them
	const channel: Channel<actions.GetBackflowProductsAvailabilityAction> = yield actionChannel(actions.getBackflowProductsAvailability.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.GetBackflowProductsAvailabilityAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(loadBackflowProductsAvailability, action),
			cancel: take(actions.cancelLoadBackflowProductsAvailability)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(loadBackflowProductPricesChannel)
	yield fork(loadBackflowProductsAvailabilityChannel)
	yield takeEvery(actions.fetchBackflowProductsByBrand.started, handleFetchBackflowProductsByBrand)
	yield takeEvery(actions.fetchBackflowProductsByType.started, handleFetchBackflowProductsByType)
	yield takeEvery(actions.fetchBackflowProductsByBrand.done, handleBackflowProductsLoaded)
	yield takeEvery(actions.fetchBackflowProductsByType.done, handleBackflowProductsLoaded)
	yield takeEvery(actions.sendContactUsBackflow.started, handleSendContactUs)
}