import { Channel, SagaIterator } from 'redux-saga'
import { actionChannel, call, flush, fork, put, race, select, take, takeEvery } from 'redux-saga/effects'
import { ProductPriceRequestList } from 'typescript-fetch-api'
import { BranchProductsRequest } from 'typescript-fetch-api/models/BranchProductsRequest'

import * as actions from './actions'
import * as supplierFilterActions from '../supplierFilter/actions'
import { productCategorySelector } from './selectors'
import { callApi } from '../api/functions'
import { getBranchApi, getContentApi } from '../api'

function* loadProductsForNewFilters(): SagaIterator {
	// load products after supplier/brand filter has changed
	// - need category id - this might come from navigation props instead but fetching off Redux store for now
	const productCategory = (yield select(productCategorySelector)) as string
	yield put(actions.loadProducts.started({ categoryId: productCategory }))
}

function* loadProductsForClearedFilters(): SagaIterator {
	// load products after supplier/brand filters were cleared
	// need category id - this might come from navigation props instead but fetching off Redux store for now
	const productCategory = (yield select(productCategorySelector)) as string
	// only load if user has selected a category
	if (productCategory) {
		yield put(actions.loadProducts.started({ categoryId: productCategory }))
	}
}

function* loadProductPrices(action: actions.LoadProductPricesAction): SagaIterator {
	// @ts-ignore API
	yield call(callApi, action, actions.loadProductPrices, ({ skus, customerId }: actions.LoadProductPricesPayload) => {
		if (skus.length === 0) return
		const products: ProductPriceRequestList = { products: skus }
		return getContentApi().getProductPrices({ productPriceRequestList: products, customerId })
	})
}

function* loadProductsAvailability(action: actions.GetProductsAvailabilityAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.getProductsAvailability,
		({ skus, branchId }: actions.GetProductsAvailabilityPayload) => {
			const products: BranchProductsRequest = { products: skus }
			return getBranchApi().getBranchProducts({ branchId, branchProductsRequest: products })
		}
	)
}

function* handleRequestImages(action: actions.RequestImagesAction): SagaIterator {
	yield call(
		// @ts-ignore API
		callApi,
		action,
		actions.requestImages,
		({ skus, customerId }: actions.RequestImagesPayload) => {
			return getContentApi().requestImages({ customerId, requestImagesRequest: { products: skus } })
		}
	)
}

/**
 * This channel allows the queueing of requests for loading product prices.
 * If we have at any moment four actions, we want to handle the first REQUEST action, then only after finishing this action we process the second action and so on...
 * NOTE: This channel also handles clearing the channel queue whenever a cancel action is dispatched.
 */
function* loadProductPricesChannel() {
	// 1. Create a channel to watch for `loadProductPrices` actions and queue them
	const channel: Channel<actions.LoadProductPricesAction> = yield actionChannel(actions.loadProductPrices.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.LoadProductPricesAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(loadProductPrices, action),
			cancel: take(actions.cancelLoadProductPrices)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

function* loadProductsAvailabilityChannel() {
	// 1. Create a channel to watch for `getProductsAvailability` actions and queue them
	const channel: Channel<actions.GetProductsAvailabilityAction> = yield actionChannel(actions.getProductsAvailability.started)

	while (true) {
		// 2. Pop request from the channel
		const action: actions.GetProductsAvailabilityAction = yield take(channel)

		const { cancel } = yield race({
			// use a blocking call and perform the requests sequentially
			task: call(loadProductsAvailability, action),
			cancel: take(actions.cancelLoadProductsAvailability)
		})

		if (cancel) {
			// clear the channel queue
			yield flush(channel)
		}
	}
}

export default function* (): SagaIterator {
	yield fork(loadProductPricesChannel)
	yield fork(loadProductsAvailabilityChannel)
	yield takeEvery(supplierFilterActions.saveFiltersAction, loadProductsForNewFilters)
	yield takeEvery(supplierFilterActions.saveExclusiveBrandFiltersAction, loadProductsForNewFilters)
	yield takeEvery(supplierFilterActions.clearFilters, loadProductsForClearedFilters)
	yield takeEvery(actions.requestImages.started, handleRequestImages)
}